.Reel-video {
  width: 100%;
}

.Reel-wrapper {
  /* height: 100%; */
}

.video-outside {
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 */
  position: relative;
  margin-bottom: var(--m);
}

.video-outside > iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.Reel-heading {
  margin: 0px 0px 18px 0px;
  border: 3px solid white;
  padding: 16px 0px;
  font-size: 36px;
  text-align: center;
}
