.Navbar-outer {
  min-width: 240px;
  height: 80px;
  padding: var(--m) var(--m);
  display: flex;
  background: #3f4142;

  justify-content: space-between;
  align-items: center;
  /* position: fixed; */
  /* width: 100%; */
  box-sizing: border-box;
  /* border-bottom: solid 1px rgba(255, 255, 255, 0.233); */
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px;
  z-index: 100;
  position: fixed;
  width: 100%;
}

.UnderConstruction {
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 1000;
  margin: 8px;
  background: var(--tertiary);
  padding: var(--s);
  text-align: center;
  font-family: var(--font--secondary);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px;
  opacity: 0.7;
}

.Navbar-title {
  font-size: var(--xl);
  /* font-weight: var(--weight-bold); */
  display: flex;
  align-items: center;
  font-family: var(--font--secondary);
  font-size: var(--m);
}

.Navbar-logo {
  /* filter: drop-shadow(1px 1px 1px #2e2e2e4b); */
  width: 64px;
  height: auto;
}

.Navbar-links {
  display: flex;
  margin-right: 50px;
}

.Navbar-link {
  color: rgb(202, 202, 202);
  margin-right: var(--m);
  font-weight: 700;
  font-size: var(--font-small);
  transition: all 0.3s;
  padding: var(--m);
  -webkit-tap-highlight-color: transparent;
}

.Navbar-link:hover {
  color: white;
  background: var(--tertiary);
}

.Navbar-selected {
  color: white;
  background: var(--primary);
}

.Navbar-selected:hover {
  /* color: var(--tertiary); */
  color: white;
  background: var(--primary);
}

/* @media only screen and (max-width: 768px) {
  .Navbar-outer {
    padding: var(--s);
  }
  .Navbar-title {
    font-size: var(--m);
  }
}

@media only screen and (min-width: 992px) {
  .Navbar-outer {
    padding: var(--l);
  }
}

@media only screen and (min-width: 1200px) {
} */
