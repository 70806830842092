.BlogInlineMedia-wrapper {
  /* border-left: solid 4px white; */
}

.BlogInlineMedia-imageWrapper {
  display: flex;
  justify-content: center;
  background: rgb(29, 29, 31);
  margin-bottom: var(--xs);
}

.BlogInlineMedia-image {
  width: 100%;
}

.BlogInlineMedia-subtitle {
  color: white;
  font-size: var(--font-small);
  padding: var(--xs) var(--s);
  opacity: 0.6;
  transition: all 0.1s;
  border-left: solid 2px white;
  margin-block-end: 1em;
  line-height: 1.25rem;
}

.BlogInlineMedia-subtitle:hover {
  opacity: 1;
}
