.PageWrapper {
  /* background: rgb(52, 54, 59); */
  background-color: rgb(43, 43, 46);
  flex-grow: 1;
  padding: 100px var(--xl) 64px var(--xl);
  display: flex;
  justify-content: center;
}

.PageWrapper-inner {
  width: 100%;
  max-width: 1200px;
  flex-grow: 1;
}

@media only screen and (max-width: 768px) {
  .PageWrapper {
    padding: var(--xl) var(--m);
    padding-top: 100px;
  }
}

::-webkit-scrollbar-thumb {
  background-color: rgb(255, 255, 255);
  /* outline: 1px solid slategrey; */
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
