/**
* Welcome to your ✨𝔲𝔱𝔦𝔩𝔦𝔱𝔶 𝔰𝔱𝔶𝔩𝔢𝔰✨!
* This file includes utility classes that are super simple
* and can be used to add general styles; variable definitions
* for colors; and styles to html, body, and other high level
* DOMs.
* 
* All utility classes start with a `u-` and all do
* one basic CSS thing (for example, making the font-weight
* 600 for bolding) or are super generic.
* 
* This is 𝙉𝙊𝙏 the place to define classes for components or
* do rigorous styling. You shoud not need to change this file
* much after initial creation.
*/

@import url("https://fonts.googleapis.com/css?family=Work+Sans:300,600");
@import url("https://fonts.googleapis.com/css?family=Space+Mono:400, 700");
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:300,400,500,600,700,800,900");

:root {
  --primary: #606c38;
  --primary--dim: #8f9c65;
  --secondary: #cb997e;
  --tertiary: #754043;
  --darkgrey: #d4d4d4;
  --medgrey: #e0e0e0;
  --grey: #f7f7f7;
  --white: #fff;

  --background: #646464;

  --xs: 4px;
  --s: 8px;
  --m: 16px;
  --l: 24px;
  --xl: 32px;

  --font-small: 14px;
  --font-medium: 18px;
  --font-large: 24px;

  --weight-bold: 600;
  --weight-regular: 300;

  --font--secondary: "Space Mono", monospace;
  --font--main: "Work Sans", sans-serif;
  --font--code: "Source Code Pro", monospace;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: "Open Sans", sans-serif; */
  font-family: var(--font--main);
  font-weight: 300;
  color: white;
}

form {
  flex-grow: 1;
}

.u-grow {
  flex: 1;
}

.u-flex {
  display: flex;
}

.u-flexColumn {
  display: flex;
  flex-direction: column;
}

.u-flex-justifyCenter {
  justify-content: center;
}

.u-flex-alignCenter {
  align-items: center;
}

.u-inlineBlock {
  display: inline-block;
}

.u-bold {
  font-weight: 600;
}

.u-textCenter {
  text-align: center;
}

.u-relative {
  position: relative;
}

.u-pointer {
  cursor: pointer;
}

.u-link {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

.u-link:hover {
  color: var(--primary--dim);
}

.u-noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  text-decoration: none;
}
