.BlogSection-wrapper {
  background: rgb(58, 60, 65);
  padding: var(--m);
  margin-bottom: var(--l);
  /* height: 800px; */
  scroll-margin-top: 100px;
}

.BlogSection-header {
  text-transform: uppercase;
  font-size: 24px;
}

